<template>
    <!-- Formulaire -->
            <v-card fluid class="ma-2 pa-5 elevation-2 rounded-lg">
                <v-layout row wrap>
                <v-flex xs9 class="pa-2">
                    <v-alert border="left" colored-border color="deep-purple accent-4" elevation="2" >
Utilisation de la cagnotte : cliquer sur + ou – dans la ligne de l’entreprise partenaire pour
obtenir le montant voulu. <br />
Incrémentation par 5 euros.
                    </v-alert>
                </v-flex>
                <v-flex xs3 class="pa-2">
                    <v-chip class="ma-2" color="secondary" outlined >
                        <v-icon left>mdi-server-plus</v-icon>
                        Cagnotte disponible : 0€
                    </v-chip>
                    <v-chip class="ma-2" color="orange" outlined >
                        <v-icon left>mdi-server-plus</v-icon>
                        Cagnotte restante : 0€
                    </v-chip>
                </v-flex>
                <v-flex xs12 class="pt-6 pa-4">
                    <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Entreprise partenaires</th>
                                <th class="text-left">Montant</th>
                                <th class="text-left">&nbsp;</th>
                                <th class="text-left">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Entreprise 1</td>
                            <td>{{ credit.a }}</td>
                            <td><v-btn @click="credit.a -= 5" class="mx-2" elevation="1" fab dark small color="orange"><v-icon dark>mdi-minus</v-icon></v-btn></td>
                            <td><v-btn @click="credit.a += 5" class="mx-2" elevation="1" fab dark small color="primary"><v-icon dark>mdi-plus</v-icon></v-btn></td>
                        </tr>
                        <tr>
                            <td>Entreprise 2</td>
                            <td>{{ credit.b }}</td>
                            <td><v-btn @click="credit.b -= 5" class="mx-2" elevation="1" fab dark small color="orange"><v-icon dark>mdi-minus</v-icon></v-btn></td>
                            <td><v-btn @click="credit.b += 5" class="mx-2" elevation="1" fab dark small color="primary"><v-icon dark>mdi-plus</v-icon></v-btn></td>
                        </tr>
                        <tr>
                            <td>Entreprise 3</td>
                            <td>{{ credit.c }}</td>
                            <td><v-btn @click="credit.c -= 5" class="mx-2" elevation="1" fab dark small color="orange"><v-icon dark>mdi-minus</v-icon></v-btn></td>
                            <td><v-btn @click="credit.c += 5" class="mx-2" elevation="1" fab dark small color="primary"><v-icon dark>mdi-plus</v-icon></v-btn></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-simple-table>
                </v-flex>
                </v-layout>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    class="rounded-xl" :disabled="!validForm" large color="success" :loading="loading.fetchData" @click.native="requestGood()">
                    Générer la demande de bon d'achat
                </v-btn>
                </v-card-actions>
            </v-card>
    </template>
    
    <script>
    import { mapWritableState, mapActions } from 'pinia'
    import { useSponsorshipStore } from '@/stores/sponsorship'
    
    export default {
        name: 'sponsorship-edit',
        props: [],
        components: { },
        data: function () {
            return {
                loading: { fetchData: true },
                form: {},
                credit: {
                    a: 10, b: 5, c:0
                }
            }
        },
        computed: {
            ...mapWritableState(useSponsorshipStore, {item: 'item' }),
            validForm(){
                let valid = false    
                return valid
            }
        },
        mounted:function (){
            this.loading.fetchData = false
        },
        methods: {
            ...mapActions(useSponsorshipStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
            async requestGood () {
                //await this.storeItem()
                this.$root.$toast({ color: 'success', text: 'Demande envoyée !' })
            },
        },
    }
    </script>