<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle" >
        <template #right><v-btn small rounded color="primary" href="#/types/0">Ajouter un type</v-btn></template>
    </PageHeader>

    <TypesForm v-if="( $route.params.typ_id !== undefined && $route.params.typ_id.length >= 0 )"/>
    <v-card v-else class="ma-4 rounded-lg">
        <TypesList />
    </v-card>

</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import TypesList from '@/components/types/TypesList'
import TypesForm from '@/components/types/TypesForm'

export default {
    name: 'types-component',
    components: { PageHeader, TypesList, TypesForm },
    props: ['contextStyle'],
    data () {
        return { }
    },
    methods: {

    }
}
</script>