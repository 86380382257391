<template>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem" class="ma-4 rounded-lg elevation-0">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                            hide-details v-model="search" class="pa-2 rounded-xl">
                </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed rounded class="white--text" to="/tasktypes/0">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.tty_description`]="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 300px;">
                {{ item.tty_description }}
            </span>
        </template>
        <template v-slot:[`item.tty_sta_id`]="{ item }">
            <v-chip class="ma-2" outlined :color="stateListById[item.tty_sta_id].sta_color">
                {{ stateListById[item.tty_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.tty_id`]="{ item }">
            <v-btn depressed class="ml-2 rounded-xl" color="amber darken-1" :href="'/#/tasktypes/' + item.tty_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useTaskTypeStore } from '@/stores/tasktype'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'tasktype-edit',
    props: [ ],
    computed: {
        ...mapState(useTaskTypeStore, {items: 'itemList' }),
        ...mapState(useTypeStore,     {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore,    {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            loading: { fetchData: false },
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',          align: 'left', value: 'tty_name'},
                { text: 'Description',  align: 'left', value: 'tty_description'},
                { text: 'Statut',       align: 'left', value: 'tty_sta_id'},
                { text: 'Action',  align: 'right', value: 'tty_id'},
            ],
        }
    },
    async mounted(){
        this.fetchItems('?per_page=false')
    },
    methods: {
        ...mapActions(useTaskTypeStore, {fetchItems: 'fetchItems'}),
    },
}
</script>