<template>
    <router-view ></router-view>
</template>
<script>

export default {
  name: 'App',
  data: () => ({
    loginState : ''
  }),
  mounted() {
    this.$root.$style = {
      cardFormClass: 'ma-2 pa-3 elevation-0 rounded-xxl micorise-v-card',
      formTextFieldOutlined: true,
      formTextFieldDense: true,
      formTextFieldClass:'rounded-lg'
    }
  },
  components: {

  }
}
</script>

<style lang="scss">
@import './assets/leaflet.css';
@import './styles/variables.scss';
</style>