<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" :hide-default-header="false"
        :items-per-page-options="pagination.rowsPerItem">

        <template v-slot:[`item.ent_name`]="{ item }"><v-chip class="ma-2" color="secondary">{{ item.ent_name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.spo_sta_id`]="{ item }">
            <v-chip class="ma-2" outlined :color="stateListById[item.spo_sta_id].sta_color">
                {{ stateListById[item.spo_sta_id].sta_label }}
            </v-chip>
        </template>
        <template v-slot:[`item.spo_credit_used`]="{ item }">
            <v-chip class="ma-2" outlined :color="'green'">
                {{ item.spo_credit_used }}
            </v-chip>
        </template>
        <template v-slot:[`item.spo_customfields`]="{ item }">
            <v-chip class="ma-2" color="secondary">
                {{ (item.spo_customfields) ? item.spo_customfields['usr_email'] : '' }}
            </v-chip>
        </template>
        <template v-slot:[`item.spo_id`]="{ item }">
            <v-btn v-if="item.spo_sta_id == 'SPO01'" rounded color="orange" elevation="0" dark @click="reSendInvite(item.spo_id)">
                <v-icon left >mdi-pencil</v-icon> Renvoyer
            </v-btn>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-send-check-outline">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSponsorshipStore } from '@/stores/sponsorship'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'entities-list',
    computed: {
        ...mapState(useSponsorshipStore, {itemList: 'itemList' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
    },
    data: function () {
        return {
            loading: { fetchData: true },
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 100, rowsPerItem: [15,25,100,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Etat',     value: 'spo_sta_id', align: 'left'},
                { text: 'Crédit(s)',   value: 'spo_credit_used', align: 'left'},
                { text: 'Société',  value: 'spo_customfields', align: 'left'},
                { text: 'Action',   value: 'spo_id', align: 'right'},
            ],
        }
    },
    async mounted () {
        await this.fetchItems('?per_page=false&extended=withcompany')
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useSponsorshipStore, {fetchItems: 'fetchItems', sendInvite: 'sendInvite'}),
        reSendInvite(id){
            this.sendInvite(id)
        }
    },
}
</script>