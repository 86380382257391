import ApiService from '@/services/api'
import { defineStore } from 'pinia'

const ENDPOINT = '/worktimes/'
const ITEM_ID  = 'wti_id'

export const useWorkTimeStore = defineStore('worktimes', {
  // convert to a function
  state: () => ({
    item: { },
    itemList: []
  }),
  getters: {

  },
  actions: {
    fetchItem (id, options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? '/' + options : ''
            if( id && id > 0 ){
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            } else {
                this.RESET_ITEM()
                resolve(this.item)
            }
        })
    },
    fetchItems (options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? options : ''
            ApiService.get( ENDPOINT + options ).then( (response) => {
                this.metaTask = response.data.meta
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                }
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    storeItem () {
        return new Promise( (resolve, reject) => {
            ApiService.post( ENDPOINT, this.item ).then( (response) => {
                this.SET_ITEM(response.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    updateItem () {
        return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
                this.SET_ITEM(response.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    destroyItem (id) {
        return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                this.RESET_ITEM()
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    //***************************MUTATIONS ***************************************/
    SET_ITEM (item) {
      this.item = item
    },
    SET_ITEMS (itemList) {
      this.itemList = itemList
    },
    RESET_ITEM () {
        this.item = {
            wti_id       : 0, 
            wti_act_id   : 0, 
            wti_usr_id   : 0, 
            wti_typ_id   : 'WTI01', 
            wti_date_start: null,
            wti_date_end: null,
            wti_duration : '00:00',
            wti_validated: 0,
            wti_created_at: null,
            action : { data: { wti_sta_id: 'GEN01' } }
        }
    }
  }
})
