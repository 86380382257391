import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import francais from 'vuetify/es5/locale/fr'

const opts = {
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary:   '#8CB879',
                secondary: '#3d946d',
                accent:    '#82B1FF',
                error:     '#FF5252',
                info:      '#2196F3',
                success:   '#4CAF50',
                warning:   '#FFC107',
                barrinfo:  '#E5E5E5',
                barrtextinfo:  '#0A2A35',
            }
        }
    },
    lang: {
        locales: { francais },
        current: 'francais',
    },
}

export default new Vuetify( opts );
