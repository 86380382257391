import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/tasktypes/'
const ITEM_ID  = 'tty_id'

export const useTaskTypeStore = defineStore('tasktypes', {
    //storage option
    persist: true,
    // convert to a function
    state: () => ({
        item: { 
          tty_id: 0,  tty_sta_id: 'GEN01', tty_color: '#00AA00', tty_typ_id: 'TTY02'
        },
        itemList: [],
        itemSearchList: '',
        metaTask: {}
    }),
    getters: {

    },
    actions: {
        fetchItem (id, options) {
            return new Promise( (resolve, reject) => {
                options = (options) ? '/' + options : ''
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        fetchItems (options) {
          return new Promise( (resolve, reject) => {
            options = (options) ? options : ''
            ApiService.get( ENDPOINT + options ).then( (response) => {
              this.metaTask = response.data.meta
              if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                this.SET_ITEMS(response.data.data)
              }
              resolve('resolved')
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        storeItem () {
          return new Promise( (resolve, reject) => {
            ApiService.post( ENDPOINT, this.item ).then( (response) => {
              this.SET_ITEM(response.data)
              resolve(this.item)
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        updateItem () {
          return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
              this.SET_ITEM(response.data)
              resolve(this.item)
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        destroyItem (id) {
          return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
              resolve('resolved')
            }).catch( (error) => { 
              reject(error)
            })
          })
        },
        //***************************MUTATIONS ***************************************/
        SET_ITEM (item) {
        this.item = item
        },
        SET_ITEMS (itemList) {
        this.itemList = itemList
        },
        RESET_ITEM () {
            this.item = { tty_id: 0, tty_sta_id: 'GEN01', tty_color: '#00AA00', tty_typ_id: 'TTY02' }
        }
    }
})
