<template>
<div>
    <v-menu :ref="fieldName" v-model="dateMenu[fieldName]"
        :return-value.sync="initObjectForm[fieldName]"
        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
        offset-y min-width="290px" style="z-index:1500">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :style="(width) ? 'width:' + width + ';' : ''" 
                :outlined="$root.$style.formTextFieldOutlined" 
                :dense="$root.$style.formTextFieldDense"
                :class="$root.$style.formTextFieldClass"
                clear-icon="mdi-close-circle" clearable @click:clear="clearDate"
                v-bind="attrs" :value="(initObjectForm[fieldName]) ? $date(initObjectForm[fieldName]).format('DD/MM/YYYY') : ''"
                :label="label" :prepend-icon="prependIcon" readonly v-on="on" >
            </v-text-field>
        </template>
        <v-date-picker locale="fr-fr" no-title scrollable
            @input="saveDate"
            v-model="initObjectForm[fieldName]" first-day-of-week="1" @change="$emit('change')">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dateMenu[fieldName] = false">Annuler</v-btn>
            <v-btn color="primary" @click="saveDate">OK</v-btn>
        </v-date-picker>
    </v-menu>
</div>
</template>

<script>

export default {
    name: 'date_picker',
    props: ['value', 'objectForm', 'fieldName', 'label', 'disabled', 'customStyle', 'preicon', 'width'], 
    data: function () {
        return {
            loading     : { getVersion: false, getRessource: false },
            ischange    : false,
            //preprendIcon  : 'mdi-account',
            //params        : '',
            //isDisabled    : this.disabled || false,
            //outlined      : false,
            //dense         : false,
            //style         : (this.customStyle == undefined) ? 'z-index:2000;' : this.customStyle,
            dateMenu      : { [this.fieldName]: false },
            prependIcon   : 'mdi-calendar-today',
            initObjectForm: {}
        }
    },
    beforeMount: function(){

    },
    beforeUpdate: function(){
        this.initObjectForm = this.objectForm // Object.assign({}, this.objectForm)
        this.initObjectForm[this.fieldName] = ( this.initObjectForm[this.fieldName] == '0000-00-00' ) ? null : this.initObjectForm[this.fieldName]
        this.prependIcon = (typeof this.preicon !== 'undefined') ? this.preicon : 'mdi-calendar-today'
    },
    mounted:function (){
        this.initObjectForm = this.objectForm // Object.assign({}, this.objectForm)
        this.initObjectForm[this.fieldName] = ( this.initObjectForm[this.fieldName] == '0000-00-00' ) ? null : this.initObjectForm[this.fieldName]
        this.prependIcon = (typeof this.preicon !== 'undefined') ? this.preicon : 'mdi-calendar-today'
        //this.preprendIcon = (this.fields.preprendIcon == undefined) ? undefined : this.fields.preprendIcon
        //this.outlined     = (this.fields.outlined === true) ? true : false
        //this.dense        = (this.fields.dense === true) ? true : false
    },
    methods: {
        clearDate(){
            this.initObjectForm[this.fieldName] = null
            this.$emit('change')
        },
        todayEn(){
            let today  = new Date().toJSON().slice(0, 10)
            //today = new Date().toJSON().slice(0, 19).replace('T', ' ')
            this.$emit('input', today)
            this.$refs[this.fieldName].save( this.objectForm[this.fieldName] )
        },
        saveDate(){
            this.$emit('input', this.initObjectForm[this.fieldName])
            this.$refs[this.fieldName].save( this.initObjectForm[this.fieldName] )
        }
    }
}
</script>