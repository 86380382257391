<template>
    <v-toolbar flat >
        <v-toolbar-title :class="contextStyle.titleClass" color="barrtextinfo" class="mr-2">
            <template v-if="contextStyle.sheet">
              <v-breadcrumbs large divider=">" :items="contextStyle.breadcrumbs" :class="'pl-0'">
                  <template v-slot:item="{ item }">
                      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">{{ item.text }}</v-breadcrumbs-item>
                  </template>
              </v-breadcrumbs>
            </template>
            <template v-else>
              <v-icon large :color="contextStyle.titleColor" :class="contextStyle.titleIcon">{{ contextStyle.icon }}</v-icon>
              <span>{{ (title) ? title : contextStyle.title }}</span>
            </template>
        </v-toolbar-title>
        <slot name="left"/>
        <v-spacer></v-spacer>
        <slot name="right"/>
    </v-toolbar>
</template>

<script>
export default {
  props: {
    contextStyle: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  }
}
</script>
