<template>
    <v-container fluid class="pa-0">
        <PageHeader :contextStyle="contextStyle">
            <!-- <template #right><v-btn small rounded color="primary" href="#/sites/0">Ajouter un site</v-btn></template> -->
        </PageHeader>
    
        <SiteForm v-if="( $route.params.sit_id !== undefined && parseInt( $route.params.sit_id ) >= 0 )"/>
        <v-card v-else class="ma-4 rounded-lg">
            <SitesList />
        </v-card>
    
    </v-container>
</template>
    
<script>
import PageHeader from '@/components/ui/PageHeader'
import SitesList  from '@/components/sites/SiteList'
import SiteForm   from '@/components/sites/SiteForm'

export default {
    name: 'entities-page',
    props: ['contextStyle'],
    components: {
        PageHeader,
        SitesList, SiteForm
    },
    data: function () {
        return {
        }
    },
    computed: { },
    created(){},
    mounted: function (){

    },
    methods: {

    },
    beforeDestroy() {

    },
}
</script>